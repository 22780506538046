import React from 'react'
import { Fade } from "react-awesome-reveal";
//Import Assets
import Child1 from '../../images/child-piercing-1.jpeg'
import Child2 from '../../images/child-piercing-2.jpeg'
import Ring from '../../images/ringv3.svg'
import Satin from '../../images/satin-1.jpeg'
function Kinder() {
    return (
        <div className="main" id="kids">
            <div className="kinder-container">
                <div className="kinder-text">
                    <Fade cascade damping="0.3" fraction="0.2">
                        <h2 className="text-heading">Kinderschmuck aus Gold</h2>
                        <h3>
                            <p>Schmuck auszuwählen kann für das Kind und die Eltern eine stressvolle Zeit sein. Das Ohr By Olga Ribler hat ein Konzept entwickelt, damit der erste Ohrstecker Ihres Kindes als schönes Erlebnis in Erinnerung bleibt.</p>

                            <p>
                                Unsere Ohrstecker werden mit der Nadel und nicht mit der Pistole gestochen. Durch unsere einzigartige
                                Technologie - Made in Switzerland - sind die Ohrstecker nur 1mm dünn und verursachen weniger Schmerzen beim stechen.
                            </p>

                            <p>Ein weiterer Vorteil ist, dass mit der Nadel ein sauberer Kanal gestochen wird, was zu einer besseren Heilung führt und Kinder durch das Schussgeräusch einer Ohrsteckerpistole nicht erschrecken.</p>

                            <p>
                                Wir achten auf jedes Detail und bereiten ein lustiges, aufregendes und zugleich beruhigendes Erlebnis!
                                Ihr Kind wird von uns mit dem Namen begrüsst und erhält ein feines zuckerarmes Getränk. Vor dem anbringen des Ohrsteckers verbringt unsere Mitarbeiterin Zeit mit dem Kind, um es kennenzulernen.
                            </p>

                            <p>Nach dem Stechen machen wir von Ihnen und Ihrem Kind ein Erinnerungsfoto, stellen ein Zertifikat aus und geben ein Goodie-Bag mit kleinen Geschenken und einem Kino Ticket oder Zoo Ticket nach Wahl.</p>
                        </h3>
                    </Fade>
                </div>
                <div className="kinder-img">
                    <Ring />
                    <img src={Child1} alt="Sorgfältiges Piercing-stechen für Kinder"></img>
                    <img src={Child2} alt="Sorgfältiges Piercing-stechen für Kinder"></img>
                    <img src={Satin} alt="Seidenleichtes Piercingsstechen"></img>
                </div>
            </div>
        </div>
    )
}

export default Kinder
