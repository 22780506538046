import React from 'react'
import { Fade } from "react-awesome-reveal";
//Import Images
import SatinLong from '../../images/satin-long.jpg'
import Shrink from '../../images/shrink-piercing_1_1.mp4'
import Thread from '../../images/internal-thread-piercing_1_1.mp4'

function technologie() {
    return (
        <div className="main" id="swissmade">
            <div className="technologie-container">
                <img src={SatinLong} alt="-"></img>
                <div className="technologie-section-1">
                    <div className="text-technologie">
                        <Fade cascade damping="0.3" fraction="0.2">
                            <h2 className="text-heading">Ultradünne Piercings</h2>
                            <h3>
                            <p>Unsere Piercings werden mit der Technologie aus der Schweizer Uhrenindustrie hergestellt, weshalb sie nur 1mm dünn sind und wir beim stechen nur eine 1.2mm Nadel verwenden. </p>
                            <p>Für Sie bedeutet das weniger Schmerzen und in Kombination mit unserem Goldschmuck einen besseren und schnelleren Heilungsprozess.</p>
                            </h3>
                        </Fade>
                    </div>
                    <div className="technologie-video align-end video-adjust-top">
                        <video
                            className="video-about"
                            playsInline
                            autoPlay
                            muted
                            webkit-playsinline="true"
                            loop
                            src={Shrink}
                        >
                        </video>
                    </div>
                </div>
                <div className="technologie-section-2">
                    <div className="text-technologie">
                        <Fade cascade damping="0.3" fraction="0.2">
                            <h2 className="text-heading">Innengewinde</h2>
                            <h3>
                                Verlieren Sie keine Piercings mehr! Unsere Piercings haben ein spezielles Gewinde, mit welchem es unmöglich ist Ihr Schmuckstück zu verlieren.
                            </h3>
                        </Fade>
                    </div>
                    <div className="technologie-video video-adjust-bottom">
                        <video
                            className="video-about"
                            playsInline
                            autoPlay
                            muted
                            webkit-playsinline="true"
                            loop
                            src={Thread}
                        >
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default technologie
