import React from 'react'
import { Fade } from "react-awesome-reveal";

//Import Images
import Ring from '../../images/ringv3.svg'
import DasOhr from '../../images/logo-das-ohr-v2-full-cutout-black.png'
import PiercingSt1 from '../../images/piercing-story-1.jpg'
import PiercingSt2 from '../../images/piercing-story-2.jpg'
import PiercingSt3 from '../../images/piercing-story-3.jpg'

function story() {
    return (
        <div className="main" id="story">
            <div className="container-story">
                <div className="story-img">
                    <Fade cascade damping="0.3" delay="1" triggerOnce="true" fraction="0">
                        <img src={PiercingSt2} alt="Piercing Diamant" className="piercingst2"></img>
                        <img src={PiercingSt1} alt="Gold Piercing " className="piercingst1"></img>
                        <img src={PiercingSt3} alt="Frau" className="piercingst3"></img>
                        <img src={DasOhr} alt="Das Ohr by Olga Ribler" className="do-logo-story"></img>
                    </Fade>
                    <Ring className="ring-st"/>
                </div>
                <div className="story-text">
                    <Fade cascade damping="0.3" fraction="0.2">
                        <h2 className="text-heading">Unsere Geschichte</h2>
                        <h3>
                            <p>By Olga Ribler stellt Schmuck seit fast 30 Jahren her.</p>
                            <p>
                                Unsere Stammkunden haben lange nach Piercings aus Gold gefragt.
                                Der Entscheid Piercings herzustellen fiel, als ihre Tochter ein Piercing wollte.
                            </p>
                            <p>Da Olga Ribler, Gründerin von by Olga Ribler, nur das Beste für sie wollte, hat sie ein Piercing kreiert. </p>
                            <p>Eine neue Passion ist entstanden, wobei sie für ihre Kunden ebenfalls angefangen hat Custom Piercings herzustellen.</p>
                            <p>Um die Hingabe und Qualität der Piercings für alle zugänglich zu machen ist Das Ohr by Olga Ribler entstanden.</p>
                        </h3>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default story
