import React from 'react'

//Import Component
import NewsletterMailchimp from './NewsletterMailchimp'

//Import Assets
import Newsletter1 from '../../images/newsletter/newsletter-1-1.png'
import Newsletter2 from '../../images/newsletter/newsletter-2-2.png'
import Newsletter3 from '../../images/newsletter/newsletter-3.png'
import Newsletter4 from '../../images/newsletter/newsletter-4-4.png'

function Newsletter() {
    return (
        <div className="main">
            <div className="news-container">
                <div className="news-img-left">
                    <img src={Newsletter1} alt="Piercing Das Ohr by Olga Ribler"></img>
                    <img src={Newsletter2} alt="Piercing Das Ohr by Olga Ribler"></img>
                </div>
                <div className="news-text">
                    <h2 className="text-heading">Newsletter</h2>
                    <h3>Erhalten Sie spezielle Angebote und News mit unserem Newsletter</h3>
                    <NewsletterMailchimp />
                </div>
                <div className="news-img-right">
                <img src={Newsletter3} alt="Piercing Das Ohr by Olga Ribler"></img>
                <img src={Newsletter4} alt="Piercing Das Ohr by Olga Ribler"></img>
                </div>
            </div>
        </div>
    )
}

export default Newsletter
