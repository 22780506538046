import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Fade } from "react-awesome-reveal";


//Import Images and SVG
import Instagram from '../../images/instagram.svg'
import Facebook from '../../images/facebook.svg'
import Youtube from '../../images/youtube.svg'
import Ring from '../../images/ringv3.svg'
import HeroImg from '../../images/hero.jpg'

function hero() {
    return (
        <div className="main">
            <div className="container-hero">
                <div className="rotate-content">
                    <div className="rotate-text">
                        <div className="rotation"><span><AnchorLink to="/#gratis" title="Model werden">Model werden</AnchorLink></span></div>
                    </div>
                    <div className="rotate-line">
                        <div className="line">
                            <hr></hr>
                        </div>
                    </div>
                    <div className="rotate-icons">
                        <a target="_blank" href="https://www.youtube.com/channel/UCN2P6Eqz4qH-B03YGq66m9A">
                            <Youtube alt="Das Ohr auf Youtube" />
                        </a>
                        <a target="_blank" href="https://www.instagram.com/olgaribler_ch/">
                            <Instagram alt="Das Ohr auf Instagram"/>
                        </a>
                        <a target="_blank" href="https://www.facebook.com/pg/byolgaribler/posts/">
                            <Facebook alt="Das Ohr auf Facebook" />
                        </a>
                    </div>
                </div>
                <div className="hero-content">
                    <Fade cascade damping="0.2">
                        <h1>Ohren-Piercings aus <br></br>Echtgold</h1>
                        <h2>Produziert in Luzern aus Fair Trade Gold mit echten Edelsteinen und Diamanten<br></br> - Made in Switzerland</h2>
                        <h3>Aktion: Gratis Piercing stechen am 10. Juni und 11. Juni in Zürich</h3>
                    </Fade>
                        <AnchorLink to="/#termin" title="Termin" className="button-anchor">
                            <span>Termin vereinbaren</span>
                        </AnchorLink>
                </div>
                <div className="hero-img">
                    <Ring className="ring" />
                    <img src={HeroImg} alt="Piercing von der Marke das Ohr" className="HeroImg"></img>
                </div>
            </div>
        </div>
    )
}

export default hero
