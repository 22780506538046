import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class NewsletterMailchimp extends React.Component {

    constructor(){
        super()
        this.state = {email: "", result: null}
    }

    _handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email)
        this.setState({result: result})
    }

    handleChange = event => {
        this.setState({email: event.target.value})
    }

    render () {
        return this.state.result === "success" ? (
            <div><h3>Herzlichen Dank für die Anmeldung</h3></div>
        ) : this.state.result === "error" ? (
            <div><h3>Bitte versuchen Sie es später erneut</h3></div>
        ) : (
          <form onSubmit={this._handleSubmit}>
            <input 
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            placeholder="E-Mail Adresse"
            onChange={this.handleChange}
            />

            <button
            type="submit"
            >
            Anmelden
            </button>
          </form>
        )
      }
}