import React from 'react'

//Import Components
import Hero from '../components/Hero/hero'
import Story from '../components/Story/story'
import GratisPiercing from '../components/Gratis-Piercing/gratisPiercing'
import Technologie from '../components/Technologie/technologie'
import Wunschpiercing from '../components/Wunschpiercing/wunschpiercing'
import Termin from '../components/Termin/termin'
import Kinder from '../components/Kinder/kinder'
import Newsletter from '../components/Newsletter/Newsletter'
import Layout from '../components/layout'
import Seo from '../components/seo'


//Schema.org
const schema ={
"@context": "http://schema.org",
"@graph": 
[
  {
    "@type": "JewelryStore",
    "name": "Das Ohr by Olga Ribler",
    "logo": "https://www.dasohr.shop/assets/das-ohr-logo.jpg",
    "image": "https://www.dasohr.shop/assets/das-ohr-brunngasse.jpg",
    "url": "https://www.dasohr.shop",
    "telephone": "044 252 62 52",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Brunngasse 3",
      "addressLocality": "Zürich",
      "postalCode": "8001",
      "addressCountry": "CH"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 47.37359405088546,
      "longitude": 8.5443232613795
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "11:00",
      "closes": "18:00"
    } 
  },
  {
     "@type": "WebSite",
     "name": "Das Ohr - by Olga Ribler",
     "url": "https://www.dasohr.shop/",
     "description": "Produziert in Luzern aus Fair Trade Gold mit echten Edelsteinen und Diamanten - Made in Switzerland",
     "potentialAction": {
       "@type": "SearchAction",
       "target": "https://www.dasohr.shop/search?q={search_term_string}",
       "query-input": "required name=search_term_string"
     }
  },
  {
    "@type": "Corporation",
    "name": "Das Ohr - by Olga Ribler",
    "alternateName": "DO",
    "url": "https://www.dasohr.shop/",
    "logo": "https://www.dasohr.shop/assets/das-ohr-echt-gold-piercing.png"
  },
  {
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Das Ohr by Olga Ribler",
      "item": "https://www.dasohr.shop/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Model werden",
      "item": "https://www.dasohr.shop/#gratis"  
    },{
      "@type": "ListItem", 
      "position": 3, 
      "name": "Unsere Geschichte",
      "item": "https://www.dasohr.shop/#story"  
    }]
  }
]
}


function Home() {
    return (
        <Layout>
            <Seo 
                title="DO"
                description="Produziert in Luzern aus Fair Trade Gold mit echten Edelsteinen und Diamanten - Made in Switzerland. Jetzt Gratis Piercing erhalten.💎"
                schemaMarkup={schema} 
            />
            <div>
                <Hero />
                <Story />
                <GratisPiercing />
                <Technologie />
                <Wunschpiercing />
                <Termin />
                <Kinder />
                <Newsletter />
            </div>
        </Layout>
    )
}

export default Home
