import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Fade } from "react-awesome-reveal";
//Import Images
import Ring from '../../images/ringv3.svg'
import WunschPiercing1 from '../../images/wunschpiercing/wunschpiercing-1.jpg'
import WunschPiercing2 from '../../images/wunschpiercing/wunschpiercing-2.jpg'
import WunschPiercing3 from '../../images/wunschpiercing/wunschpiercing-3.jpg'
import WunschPiercing4 from '../../images/wunschpiercing/wunschpiercing-4.jpg'
import WunschPiercing5 from '../../images/wunschpiercing/wunschpiercing-5.jpg'
import WunschPiercing6 from '../../images/wunschpiercing/wunschpiercing-6.jpg'
import WunschPiercing7 from '../../images/wunschpiercing/wunschpiercing-7.jpg'
import WunschPiercing8 from '../../images/wunschpiercing/wunschpiercing-8.jpg'

function wunschpiercing() {
    return (
        <div className="main" id="wartezeit">
            <div className="wp-container">
                <div className="wp-title">
                    <Fade fraction="0.2">
                        <h2 className="text-heading">Erhalten Sie sofort Ihr Wunschpiercing</h2>
                    </Fade>
                </div>
                <div className="wp-inner-container">
                    <div className="wp-img-left">
                            <img src={WunschPiercing1} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                            <img src={WunschPiercing2} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                            <img src={WunschPiercing3} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                            <img src={WunschPiercing4} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                    </div>
                    <div className="wp-content">
                        <div className="wp-ring">
                            <Ring />
                        </div>
                        <div className="wp-text">
                            <Fade cascade damping="0.3" fraction="0.2">
                                <h3>
                                    <p>Da unsere Piercings aus Gold gefertigt sind entsteht dank der einzigartigen Eigenschaften von Gold kein langer Heilungsprozess.</p>
                                    <p className="text-content-secondary">Erhalte sofort deinen Style!</p>
                                    <p>Das von Ihnen ausgewählte Piercing wird sofort gestochen ohne Übergangsphase! </p>
                                    <p>Nach dem Heilungsprozess wird lediglich das Hinterteil des Piercings durch ein kürzeres, perfekt sitzendes Hinterteil ersetzt.</p>
                                </h3>
                            </Fade>
                            <AnchorLink to="/#termin" title="Our team" className="button-anchor">
                                <span>Termin vereinbaren</span>
                            </AnchorLink>
                        </div>
                    </div>
                    <div className="wp-img-right">
                        <img src={WunschPiercing5} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                        <img src={WunschPiercing6} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                        <img src={WunschPiercing7} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                        <img src={WunschPiercing8} alt="Wunschpiercing Das Ohr by Olga Ribler"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default wunschpiercing
