import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Parallax } from 'react-parallax';
import { Fade } from "react-awesome-reveal";
//Import Images
import Satin from '../../images/satin-2.jpg'
import Ring from '../../images/ringv3.svg'


function gratisPiercing() {
    return (
        <div className="main" id="gratis">
            <div className="container-grpiercing">
                <div className="grpiercing-text">
                    <Fade cascade damping="0.3" fraction="0.2">
                        <h2 className="text-heading">Gratis Piercing stechen</h2>
                        <h3>
                        <p>
                            Lassen Sie sich Gratis piercen und bezahlen Sie nur für das Schmuckstück.
                        </p>
                        <p>Das Piercing wird unter Aufsicht von Lehrern der London Academy of Piercing von unseren Piercing Student*innen gestochen. </p>
                        
                        <p>
                            Kommen Sie als Model zu uns und geniessen Sie einen Tee oder erfrischenden Drink in unserer Lounge.
                            Wir machen das Piercen mit unserem Package zum Erlebnis.
                        </p>
                        
                        <p>Jedes einzelne Piercing wird genau besprochen, wobei wir mit Ihnen, basierend auf Ihrer Ohrform und Ihrem Look einen einzigartigen Style kreieren.</p>
                        </h3>
                    </Fade>
                    <AnchorLink to="/#termin" title="Our team" className="button-anchor">
                        <span>Termin vereinbaren</span>
                    </AnchorLink>
                </div>
                <div className="grpiercing-img">
                    <div className="grpiercing-img-container">
                        <Parallax bgImage={Satin} bgImageAlt="Seidenglatte, hochpolierte Goldstruktur" strength={400} className="parallax">
                            <div className="grpiercing-list" >
                                <ul>
                                    <li>Ohren Styling Konsulation</li>
                                    <li>Sofort Wunschpiercing erhalten</li>
                                    <li>Geschenk + Pflegeset</li>
                                    <li>Piercing after care Termin</li>
                                    <li>Wechseln des Hinterteils nach der kompletten Heilung</li>
                                </ul>
                            </div>
                        </Parallax>
                        <Ring className="ring"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default gratisPiercing
