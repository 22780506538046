import React, {useRef} from 'react'
import emailjs from 'emailjs-com'

//Import From Styling
import "../../styles/components/terminForm/terminForm.scss";
import Formular from '../../pdf/formular-risikobeurteilung-das-ohr.pdf';
import Formular16 from '../../pdf/Informierte-Zustimmung-zum-Piercing-bei-Kindern-unter-16-Jahren.pdf';

export default function TerminFormR() {

    const terminForm = useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();
    

    emailjs.sendForm(`${process.env.GATSBY_EJS_SERVICE_ID}`, `${process.env.GATSBY_EJS_TEMPLATE_ID}`, terminForm.current, `${process.env.GATSBY_EJS_USER_ID}`)
    .then((result) =>{
        console.log(result.text);
        alert("Ihre Anmeldung wurde erfolgreich an uns gesendet.");
    }, (error) =>{
        console.log(error.text);
        alert("Senden fehlgeschlagen. Bitte versuchen Sie es erneut");
    });

};

    return (
        <div>
            <form ref={terminForm} onSubmit={sendEmail}>
                {/*Name */}
                <input
                    type="text" 
                    placeholder="Name"
                    name="name"
                    required
                />
                {/*Email */}
                <input
                    type="email" 
                    placeholder="E-Mail"
                    name="email"
                    required 
                />
                {/*Phone */}
                <input
                    type="tel" 
                    placeholder="Telefon"
                    name="phone"
                    required 
                />
                {/*Birthday */}
                <input
                    type="date" 
                    placeholder="Geburtstag:"
                    name="birthday"
                    required 
                />
                {/*Termin */}
                <select placeholder="Gewünschtes Datum" name="termin">
                    <option value="" disabled selected>Gewünschtes Datum</option> 
                    <option value="10.06.2021">10.06.2021</option>
                    <option value="11.06.2021">11.06.2021</option>
                </select>
                
                <input 
                    type="time"
                    placeholder="Uhrzeit"
                    name="termintime"
                    required
                    min="11:00"
                    max="17:00"
                    list="times"
                    
                />
                <datalist id="times">
                    <option value="11:00:00"></option>
                    <option value="11:30:00"></option>
                    <option value="12:00:00"></option>
                    <option value="12:30:00"></option>
                    <option value="13:00:00"></option>
                    <option value="13:30:00"></option>
                    <option value="14:00:00"></option>
                    <option value="14:30:00"></option>
                    <option value="15:00:00"></option>
                    <option value="15:30:00"></option>
                    <option value="16:00:00"></option>
                    <option value="16:30:00"></option>
                    <option value="17:00:00"></option>
                </datalist>
                {/*Wunschpiercing */}
                <select placeholder="Wunschpiercing wählen" name="piercing">
                    <option value="" disabled selected>Wunschpiercing wählen</option>
                    <option value="Lobe">Lobe</option>
                    <option value="Low Helix">Low Helix</option>
                    <option value="Conch">Conch</option>
                    <option value="Contraconch"> Contraconch</option>
                    <option value="Helix">Helix</option>
                    <option value="Rook">Rook</option>
                    <option value="Forward Helix"> Forward Helix</option>
                    <option value="Daith">Daith</option>
                    <option value="Tragus">Tragus</option>
                    <option value="Antitragus">Antitragus</option>
                </select>
                {/*AGB */}
                <div className="form-checkbox">
                    <input 
                        type="checkbox"
                        placeholder="AGB"
                        required
                        name="agb"
                    />
                    <span>Ich habe die AGB & Datenschutzbestimmungen gelesen und bestätige sie.</span>
                </div>
                <div className="form-downloads">
                    <span><a href={Formular}>Bitte füllen Sie folgendes Formular aus und nehmen es an den Termin mit.</a></span>
                    <span><a href={Formular16}>Für unter 16-Jährige: Bitte die Einverständiserklärung ausfüllen und mitnehmen.</a></span>
                </div>
                {/*Button */}
                <button type="submit" value="Send">Senden</button>
            </form>
        </div>
    )
}
