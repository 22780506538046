import React from 'react'
import TerminFormR from './terminFormR'
//Import Images
import PiercingArten from '../../images/termin/piercing-arten-das-ohr-olga-ribler.jpg'

function termin() {
    return (
        <div className="main" id="termin">
            <div className="termin-container">
                <div className="termin-form">
                    <div className="termin-form-inner">
                        <h2 className="text-heading">Termin für Ihr Traumpiercing</h2>
                        <TerminFormR />
                    </div>
                </div>
                <div className="termin-image">
                    <div className="termin-image-inner">
                        <img src={PiercingArten} alt="Erhältliche Piercingtypen bei Das Ohr by Olga Ribler"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default termin
